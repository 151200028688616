import Vue from 'vue';
import App from './App.vue';
import router from './router';

import VueHighcharts from 'vue-highcharts';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import loadMap from 'highcharts/modules/map';
import clusterInit from "highcharts/modules/marker-clusters";


import './assets/css/main.css';
import '@mdi/font/css/materialdesignicons.min.css'

import proj4 from 'proj4';
window.proj4 = window.proj4 || proj4;

require("highcharts/modules/annotations")(Highcharts)

loadMap(Highcharts)
Vue.use(VueHighcharts, { Highcharts } );
HighchartsMore(Highcharts)
clusterInit(Highcharts)

// Vue filters

Vue.filter('reduceBigNumbers', function(number, decimals) {
    return reduceBigNumbers(number, decimals)
});
Vue.filter('numberFormat', function(number, decimals) {
    return numberFormat(number, decimals)
});


export function reduceBigNumbers(number, decimals=2) {
    if (number > 1000000 || number < -1000000) {
        return numberFormat(number / 1000000, decimals) + 'M';
    }

    if (number > 1000 || number < -1000) {
        return numberFormat(number / 1000, decimals) + 'K';
    }

    return numberFormat(number, decimals);
}

export function numberFormat(number, decimals) {

    return parseFloat(number).toLocaleString('en', {
                maximumFractionDigits: decimals,
                minimumFractionDigits: decimals
            }).replace(/,/g, '@').replace('.', ',').replace(/@/g, '.');
}

Vue.config.productionTip = false;

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
